<template>
  <div class="nav-container d-f a-c j-b">
    <div>欢迎来到教育资源网!</div>
    <div class="d-f">
      <span @click="goIndex">网站首页</span>
      <div class="popover d-f">
        <div class="avatar">
          <img src="@/assets/image/user_avatar_small.png" alt="" v-if="avatar === null" />
          <img :src="avatar" alt="" v-else />
        </div>
        <div class="username">{{ username }}</div>
        <div class="arrow">
          <img src="@/assets/image/user_popover_arrow.png" alt="" />
        </div>
        <div class="popover-hover">
          <div class="triangle-container d-f j-c">
            <div class="triangle"></div>
          </div>
          <div class="space">
            <div class="top d-f">
              <div class="left">
                <img
                  src="@/assets/image/user_avatar_big.png"
                  alt=""
                  v-if="avatar === null"
                />
                <img :src="avatar" alt="" v-else />
              </div>
              <div class="right d-f f-d j-b">
                <div class="respect">尊敬的{{ name }}</div>
                <div class="phone">{{ phone }}</div>
              </div>
            </div>
            <div class="bottom d-f j-b">
              <div class="bottom-item d-f f-d a-c" @click="goUserData">
                <img src="@/assets/image/user_popover_data.png" alt="" />
                <p>个人资料</p>
              </div>
              <div class="bottom-item d-f f-d a-c" @click="goUserCollection">
                <img src="@/assets/image/user_popover_collection.png" alt="" />
                <p>我的收藏</p>
              </div>
              <div class="bottom-item d-f f-d a-c" @click="goUserUpload">
                <img src="@/assets/image/user_popover_upload.png" alt="" />
                <p>个人上传</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span @click="logout">退出登录</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      name: "",
      phone: "",
      avatar: "",
    };
  },
  props: ["userInfo"],
  methods: {
    //去首页
    goIndex() {
      this.$router.push("/index");
    },
    //退出登录
    logout() {
      this.$ls.set("userInfo", null);
      this.$ls.set("token", null);
      this.setCookie("token", null);
      window.open("https://cloud.jsxniu.com/login", "_self");
    },
    //去个人资料
    goUserData() {
      this.$router.push("/user-information");
    },
    //去我的收藏
    goUserCollection() {
      this.$router.push("/user-collection");
    },
    //去个人上传
    goUserUpload() {
      this.$router.push("/user-upload");
    },
    // 保存cookie
    setCookie(cName, value, expiredays) {
      var exdate = new Date();
      exdate.setDate(exdate.getDate() + expiredays);
      document.cookie =
        cName +
        "=" +
        decodeURIComponent(value) +
        (expiredays == null ? "" : ";expires=" + exdate.toGMTString()) +
        ";path=/;domain=jsxniu.com";
    },
  },
  watch: {
    userInfo(newValue, oldValue) {
      this.username = newValue.username;
      this.name = newValue.name;
      this.phone = newValue.phone;
      this.avatar = newValue.avatar;
    },
  },
  created() {
    this.username = this.$ls.get("userInfo").username;
    this.name = this.$ls.get("userInfo").name;
    this.phone = this.$ls.get("userInfo").phone;
    this.avatar = this.$ls.get("userInfo").avatar;
  },
};
</script>

<style scoped lang="less">
@main-color: #05ae44;
.nav-container {
  width: 1440px;
  height: 40px;
  background: #f1f3f5;
  padding: 0 120px;
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  span {
    cursor: pointer;
  }
  .popover {
    margin-left: 24px;
    margin-right: 24px;
    position: relative;
    .avatar {
      width: 16px;
      height: 16px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .username {
      margin-left: 6px;
      max-width: 57px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: @main-color;
      cursor: pointer;
    }
    .arrow {
      width: 12px;
      height: 12px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .popover-hover {
      position: absolute;
      top: 10px;
      left: -120px;
      display: none;
      z-index: 999;
      .triangle-container {
        width: 324px;
        .triangle {
          width: 0;
          height: 0;
          border: 10px solid;
          border-color: transparent transparent #fff transparent;
        }
      }
      .space {
        width: 324px;
        background: #ffffff;
        box-shadow: 0px 0px 6px rgba(14, 52, 0, 0.1);
        .top {
          width: 100%;
          height: 100px;
          background-image: url("~@/assets/image/user_popover_bac.png");
          background-size: cover;
          padding: 20px 24px;
          box-sizing: border-box;
          .left {
            width: 60px;
            height: 60px;
            img {
              height: 100%;
              width: 100%;
              border-radius: 50%;
            }
          }
          .right {
            padding: 7px 16px;
            box-sizing: border-box;
            .respect {
              width: 150px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              font-size: 12px;
              font-weight: 400;
              color: #999999;
            }
            .phone {
              font-size: 14px;
              font-weight: 400;
              color: #063918;
            }
          }
        }
        .bottom {
          height: 124px;
          padding: 24px 42px;
          box-sizing: border-box;
          .bottom-item {
            cursor: pointer;
            img {
              width: 48px;
            }
            p {
              font-size: 12px;
              font-weight: 400;
              color: #999999;
              margin-top: 12px;
            }
          }
        }
      }
    }
  }
  .popover:hover .popover-hover {
    display: block;
  }
}
</style>
