<!--
 * @Author: your name
 * @Date: 2021-06-18 18:10:25
 * @LastEditTime: 2021-06-21 10:03:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \education_resource\src\views\user\index.vue
-->
<template>
  <div class="user-container d-f f-d a-c">
    <page-nav :userInfo="userInfo"></page-nav>
    <partition></partition>
    <Menu @changeUserInfo="changeUserInfo"></Menu>
  </div>
</template>

<script>
import PageNav from "@/components/PageNav/index.vue";
import Partition from "./partition";
import Menu from "./menu.vue";
export default {
  components: {
    PageNav,
    Partition,
    Menu,
  },
  data() {
    return {
      userInfo: {},
    };
  },
  methods: {
    changeUserInfo(val) {
      this.userInfo = val;
    },
  },
};
</script>

<style scoped lang="less">
.user-container {
  width: 100%;
  min-height: 100vh;
  background: #f1f3f5;
}
</style>
