<template>
  <div class="partition d-f a-c">
    <img src="@/assets/image/user_logo.png" class="logo" />
    <p>个人中心</p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
.partition {
  width: 100%;
  height: 60px;
  padding: 0 120px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
  .logo {
    width: 118px;
  }
  p {
    margin-left: 31px;
    font-size: 20px;
    font-weight: 400;
    color: #4d4d4d;
    margin-bottom: 0;
  }
}
</style>
