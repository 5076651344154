<template>
  <div class="menu d-f j-b">
    <a-menu
      mode="inline"
      class="menu-inline"
      :default-selected-keys="['/home']"
      :selectedKeys="[this.$route.path]"
      :default-open-keys="['sub1', 'sub2']"
    >
      <a-menu-item key="/user-home">
        <router-link to="/user-home">
          <span class="index">个人主页</span>
        </router-link>
      </a-menu-item>
      <a-sub-menu key="sub1">
        <span slot="title">
          <img src="@/assets/image/user_menu_icon1.png" alt="" class="icon" />
          <span>个人中心</span>
        </span>
        <a-menu-item key="/user-information">
          <router-link to="/user-information">个人信息 </router-link>
        </a-menu-item>
        <a-menu-item key="/user-account">
          <router-link to="/user-account">账号绑定 </router-link>
        </a-menu-item>
        <a-menu-item key="/user-repair-password">
          <router-link to="/user-repair-password">修改密码 </router-link>
        </a-menu-item>
      </a-sub-menu>
      <a-sub-menu key="sub2">
        <span slot="title">
          <img src="@/assets/image/user_menu_icon2.png" alt="" class="icon" />
          <span>资源管理</span>
        </span>
        <a-menu-item key="/user-download">
          <router-link to="/user-download">我的下载 </router-link>
        </a-menu-item>
        <a-menu-item key="/user-collection">
          <router-link to="/user-collection">我的收藏 </router-link>
        </a-menu-item>
        <a-menu-item key="/user-upload">
          <router-link to="/user-upload">上传管理 </router-link>
        </a-menu-item>
      </a-sub-menu>
    </a-menu>
    <router-view @changeUserInfo="changeUserInfo"></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openKeys: ["sub1", "sub2"],
    };
  },
  methods: {
    changeUserInfo(val) {
      this.$emit("changeUserInfo", val);
    },
  },
};
</script>

<style scoped lang="less">
.menu {
  margin-top: 24px;
  height: 710px;
  width: 1200px;
  margin-bottom: 66px;
  .ant-menu::before {
    margin-bottom: 27px;
  }
  .ant-menu:not(.ant-menu-horizontal) {
    .ant-menu-item-selected {
      background-color: #e7f1ea;
    }
  }
  .menu-inline {
    width: 240px;
    border-radius: 4px;
    .index {
      margin-left: 24px;
    }
    .icon {
      width: 16px;
      margin-right: 10px;
      vertical-align: -3px;
    }
  }
}
</style>
